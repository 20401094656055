<script setup>
	const { icon } = defineProps({
		icon: {
			type: Object,
			required: true,
		},
		copy: {
			type: String,
			required: false,
		},
	});
</script>

<template>
	<div class="cac-icon-wrapper">
		<ProjectIcon :icon="icon.src" :alt="icon.alt" :border="false" :width="60" :height="60" />
		<MessHtml :html="copy" class="h6 text-white" />
	</div>
</template>

<style lang="scss">
	.cac-icon-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
</style>
